<template>
    <ul class="wy-menu-item">
      <li v-for="(item, index) in menu" :key="index" class="wy-menu-item-item cur_p" >
        <div class="wy-menu-item-item-title top" :id="'10'+ item.id" :class="[item.active ? 'active' : '']" @click.stop="toggles(item,1)">
          <el-icon class="icon" :class="item.check ? 'up' : 'down'" >
            <ArrowDown />
          </el-icon>
          <div class="title" >
          {{ item.title }}
          </div>
        </div>
        <template v-if="hasChildren(item)">
          <transition name="slide-fade">
            <div v-show="item.check">
             <menu-sub-item :menu="item.children" :father-id="item.id" @click-item="clickItem"/>
            </div>
            </transition>
        </template>
      </li>
    </ul>
</template>
<script setup>
import { ref, toRefs } from "vue";
import { ArrowDown} from "@element-plus/icons-vue";
import MenuSubItem from './menuSubItem.vue'
const props = defineProps({
  menu:{
    type: Array,
    default: () => []
  },
  fatherId:{
    type: Number,
    default: 0
  }
})
const { menu , fatherId } = toRefs(props)
const emit = defineEmits(['open','clickItem'])
const hasChildren = (item) => {
  return Array.isArray(item.children) && item.children.length > 0
}
const toggles = (item,type)=>{
  menu.value.forEach((i) => {
    if(i === item){
      // if(type === 1){
        emit('open',item,fatherId.value)
        emit('clickItem',i.children[0],item.id)
      // }else{
      //   emit('clickItem',i.children[0],item.id)
      // }
    }
  })
}
const clickItem = (item,id)=>{
  emit('clickItem',item,id)
}
</script>

<style scoped lang="scss">
@import url('common.scss');
.wy-menu-item-item-title {
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  overflow: hidden;
  padding-left: 22px;
  &.active,&:hover {
    .icon {
      color: $mainColor;
    }

    .title {
      color: $mainColor;
    }
  }

  .icon {
    font-size: 12px;
      color: #C4C4CC;
      margin-right: 8px;
    transition: 0.1s all linear;
    &.up {
      transform: rotate(180deg);
    }
  }

  .title {
    font-size: 16px;
      color: #1C1D1F;
  }
}
</style>
