<template>
  <div v-for="item in menuList" :key="item.id">
    <div @click="toggle(item)" class="menu-title"
         :class="[item.top ?'top':'',item.child?'child':'',!item.top && !item.child ? 'third':'']">
      <el-icon class="icon" :class="item.check?'up':'down'" v-if="item.top">
        <CaretBottom/>
      </el-icon>
      <el-icon class="icon" :class="item.check?'up':'down'" v-else-if="item.child">
        <ArrowDown/>
      </el-icon>
      <div class="title" :class="[!item.top && item.active ? 'active':'']">{{ item.title }}</div>
    </div>
    <template v-if="hasChildren(item)">
      <transition name="slide-fade">
        <div v-show="item.check">
          <NestedMenu :menu="item.children" :active-id="activeId"/>
        </div>
      </transition>
    </template>
  </div>
</template>
<script setup>
import {onMounted, ref, toRefs, watch} from "vue";
import {ArrowDown, CaretBottom} from '@element-plus/icons-vue'
import {useRouter} from "vue-router";

const router = useRouter()
const props = defineProps({
  menu: {
    type: Array,
    required: true
  },
  activeId: {
    type: Number,
    default: 0
  },
  menuId: {
    type: Number,
    default: 0
  }
})
const {menu, activeId, menuId} = toRefs(props)
const hasChildren = (item) => {
  return Array.isArray(item.children) && item.children.length > 0
}

const menuList = ref([])

function setMenuList() {
  menuList.value = menu.value.map(item => {
    return {
      ...item,
      check: false,
      active: false
    }
  })
}

setMenuList()
const toggle = (item) => {
  menuList.value.forEach(i => {
    if (!item.children) {
      let dom = document.getElementById(item.id)
      dom.scrollIntoView({behavior: "instant", block: "start", inline: "nearest"})
      router.push({
        query: {
          id: item.id
        },
      })
      return
    }
    if (i.id === item.id) {
      i.check = !i.check
      if (!item.top) {
        router.push({
          path: `/shop/graphicCourse/${item.id}`,
        })
      }
    }
  })
}

function markItem(item, newVal, menuIdNewVal) {
  if (item.id === (menuIdNewVal || newVal)) {
    item.active = true;
    item.check = true;
  }
  if (item.children) {
    if (!item.check) {
      item.check = item.children.some(i => i.id === (menuIdNewVal || newVal) || (i.children && i.children.some(j => j.id === (menuIdNewVal || newVal))));
    }
    if (!item.active) {
      item.active = item.children.some(i => i.id === (menuIdNewVal || newVal));
    }
    // item.children.forEach(child => markItem(child, newVal,menuIdNewVal));
  }
  // if(item.id === (menuIdNewVal || newVal) ){
  //   item.active = true;
  //
  // }

}

watch(() => menuId.value, (newVal) => {
  menuList.value.forEach(item => markItem(item, activeId.value, newVal));
})
onMounted(() => {
  menuList.value.forEach(item => markItem(item, activeId.value, menuId.value));
})
</script>
<script>

export default {
  name: 'NestedMenu',
}
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
  transform: translateX(-10px);
}

.slide-fade-enter-to {
  transform: translateX(0);
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.menu-title {
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  overflow: hidden;

  .icon {
    transition: 0.1s all linear;

    &.up {
      transform: rotate(180deg);
    }
  }

  &.third {
    margin-left: 32px;
    border-left: 1px solid #E0E0E0;
    padding-left: 16px;

    &:hover {
      color: $mainColor;
    }

    .title {
      &.active {
        color: $mainColor;
      }
    }
  }

  &.top {
    padding-left: 0;

    .icon {
      font-size: 20px;
      color: #C4C4CC;
      margin-right: 6px;

      &:hover {
        color: $mainColor;
      }
    }

    .title {
      font-size: 20px;
      color: #1C1D1F;

      &:hover {
        color: $mainColor;
      }
    }
  }

  &.child {
    padding-left: 22px;

    .icon {
      font-size: 12px;
      color: #C4C4CC;
      margin-right: 8px;

      &:hover {
        color: $mainColor;
      }
    }

    .title {
      font-size: 16px;
      color: #1C1D1F;

      &.active {
        color: $mainColor;
      }
      &:hover {
        color: $mainColor;
      }
    }
  }

}
</style>