<template>
  <div class="graphic-course">
    <div class="graphic-course-head">
      <shop-header type="graphicCourse"></shop-header>
    </div>
    <div class="graphic-container">
      <!-- <el-breadcrumb class="graphic-bread" :separator-icon="ArrowRight">
        <el-breadcrumb-item>教程</el-breadcrumb-item>
        <el-breadcrumb-item>采购入库</el-breadcrumb-item>
        <el-breadcrumb-item>WEB创建订单</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="content dp_f">
        <el-scrollbar class="left-menu">
          <left-nav></left-nav>
        </el-scrollbar>
        <el-scrollbar class="right-content">
          <router-view v-slot="{ Component }">
            <transition name="scale" mode="out-in">
              <component :is="Component" :key="route.path"></component>
            </transition>
          </router-view>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance,provide,ref} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import {ArrowRight} from "@element-plus/icons-vue";
import LeftNav from "@views/shop/graphicCourse/components/leftMenu";
import {useRoute} from "vue-router";
const loading = ref(false)
provide('loading',loading)
const {proxy} = getCurrentInstance();
const route = useRoute()

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "graphicCourse",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
