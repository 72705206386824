<template>
  <div class="wy-menu">
    <ul class="wy-menu-index">
      <li v-for="(item, index) in menu" :key="index" class="wy-menu-index-item cur_p" >
        <div class="wy-menu-index-item-title top" @click.stop="toggle(item)">
          <el-icon class="icon" :class="item.check ? 'up' : 'down'" >
            <CaretBottom />
          </el-icon>
          <div class="title">
            {{ item.title }}
          </div>
        </div>
        <template v-if="hasChildren(item)">
          <transition name="slide-fade">
            <div v-show="item.check">
             <menu-item :menu="item.children" :father-id="item.id" @open="open" @clickItem="clickItem"/>
            </div>
            </transition>
        </template>
      </li>
    </ul>
  </div>
</template>
<script setup>
import {inject, onMounted, ref} from "vue";
import {  CaretBottom } from "@element-plus/icons-vue";
import {useRoute,useRouter} from "vue-router";
const route = useRoute()
const router = useRouter()
import MenuList from "./config";
import MenuItem from './menuItem.vue'
const menu = ref(MenuList)
const hasChildren = (item) => {
  return Array.isArray(item.children) && item.children.length > 0
}
const toggle = (item) =>{
  menu.value.forEach((i) => {
      if(item.id === i.id){
        i.check = !i.check
      }
  })
}

const open = (item,id)=>{
  menu.value.forEach(i=>{
    if(i.id === id){
        i.children.forEach(j=>{
          if(j.id === item.id){
            j.check = !j.check
            j.active = true
            router.push({
              path: `/shop/graphicCourse/${item.id}`,
            })
          }else{
            j.active = false
          }
        })
    }else{
      i.children.forEach(j=>{
        // j.check = false
        j.active = false
      })
    }
  })
}
const loading = inject('loading')
const clickItem = (item,id)=>{
  menu.value.forEach(i=>{
    i.children.forEach(j=>{
      j.active = j.id === id;
      if(j.id === id){
        router.push({
          path: `/shop/graphicCourse/${id}`,
        })
      }
      j.children.forEach((k,index)=>{
          k.active = item.id === k.id
          if(item.id === k.id){
            loading.value = true
            setTimeout(()=>{
              loading.value = false
              let dom = document.getElementById(  k.id)
              dom.scrollIntoView({
                behavior: "instant",
                block: "start",
                inline: "nearest"
              })
            },100)
          }
      })
    })
  })
}

onMounted(()=>{
  menu.value.forEach(item=>{
    if(item.children.findIndex(i=>{
      if(i.id ===Number(route.params.id)){
        i.check = true
        i.active = true
        setTimeout(()=>{
            let dom = document.getElementById('10' +  i.id)
            dom.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
            })
          },100)
        return true
      }
    }) !== -1){
      item.check = true
    }
  })
})
</script>

<style scoped lang="scss">
@import url('common.scss');
.wy-menu-index-item-title {
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  overflow: hidden;
  &.active,&:hover {
    .icon {
      color: $mainColor;
    }

    .title {
      color: $mainColor;
    }
  }

  .icon {
    font-size: 20px;
    color: #c4c4cc;
    margin-right: 6px;
    transition: 0.1s all linear;
    &.up {
      transform: rotate(180deg);
    }
  }

  .title {
    font-size: 20px;
    color: #1c1d1f;
  }
}
</style>
