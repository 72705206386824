<template>
  <form class="graphic-left-nav" @submit="goSearch">
    <el-input
    v-elInput
        v-model="formData.query"
        class="h_48 w280 search-input"
        size="large"
        placeholder="搜索教程"
        @click="goSearch"
        :prefix-icon="Search"
    />
    <div class="title mt_48">图文教程</div>
    <div class="mt_48">
      <!-- <NestedMenu :menu="MenuList" :active-id="activeId"></NestedMenu> -->
      <Menu />
    </div>
  </form>
</template>

<script setup>
import {getCurrentInstance, reactive} from "vue";
import {Search} from "@element-plus/icons-vue";
import NestedMenu from './menu.vue'
import { useRouter } from "vue-router";
import Menu from '@views/shop/graphicCourse/components/leftMenu/MenuList/index.vue'

const router = useRouter()
const goSearch = (e)=>{
  e.preventDefault()
  router.push({path:'/shop/graphicCourse/search',query:{query:formData.query}})
}
const {proxy} = getCurrentInstance();
const formData = reactive({
  query: '',
})
const props = defineProps({
  activeId: {
    type: Number,
    default: 0
  }
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "leftNav",
});
</script>

<style lang="scss" scoped>
.graphic-left-nav {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $shopFsColor;
    line-height: 1;
    user-select: none;
  }
}
</style>
