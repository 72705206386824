<template>
  <ul class="wy-menu-sub-item">
    <li v-for="(item, index) in menu" :key="index" class="wy-menu-sub-item-item cur_p" :id="index + '0' + item.id">
      <div class="wy-menu-sub-item-item-title top" :class="[  item.active ? 'active' : '']" @click="toggles(item)">
        <div class="title" >
          {{index + 1}}.{{ item.title }}
        </div>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { computed, ref, toRefs } from "vue";
import { ArrowDown} from "@element-plus/icons-vue";
import {useRoute} from 'vue-router'
const route = useRoute()
const props = defineProps({
  menu:{
    type: Array,
    default: () => []
  },
  fatherId:{
    type: Number,
    default: 0
  }
})
const { menu  , fatherId } = toRefs(props)
const emit = defineEmits(['clickItem'])
const chooseId = computed(()=>{
  return ( Number(route.query.query) || 0)
})
const toggles = (item)=>{
  emit('clickItem', item, fatherId.value)
}
</script>

<style scoped lang="scss">
.wy-menu-sub-item-item-title {
display: flex;
align-items: center;
padding: 16px 0 16px 16px;
cursor: pointer;
overflow: hidden;
margin-left: 32px;
border-left: 1px solid #E0E0E0;
  &:hover,&.active {
    .icon {
      color: $mainColor;
    }

    .title {
      color: $mainColor;
    }
  }

.title {
  font-size: 16px;
    color: #1C1D1F;
}
}
</style>
